import styled from "@emotion/styled";
import { Breadcrumbs } from "@mui/material";
import React from "react";
import { Link,} from "react-router-dom";
import DialinContent from "./DialinContent";


export default function Dialin() {
  return (
    <>
    <StyledBreadcrumbs maxItems={3} aria-label="breadcrumb">
    <StyledLink to="/">Centralized service portal</StyledLink>
      <StyledLink
        last="true"
        underline="hover"
        color="ButtonText"
        to={`/Dialin`}
      >Dial-in
      </StyledLink>
    </StyledBreadcrumbs>
    <StyledContentContainer>
     <DialinContent/>
    </StyledContentContainer>
  </>
  )
}
const StyledContentContainer = styled.div`
height: 100%;
display: flex;

justify-content: center;
`;

const StyledBreadcrumbs = styled(Breadcrumbs)`
margin: 10px;
`;

const StyledLink = styled(Link)`
text-decoration: none;
color: rgba(0, 0, 0, 0.6);
color: ${(props) => (props.last === "true" ? "black" : "rgba(0,0,0,0.6)")};
&:hover {
  text-decoration: underline;
}
`;
