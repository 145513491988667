import React, { useEffect } from "react";
import configData from "../../config.js";
import { Route, Routes } from "react-router-dom";
import Home from "./Home/Home.jsx";
import Scanners from "./Scanners/Scanners.jsx";
import Dashboard from "./Dashboard/Dashboard.jsx";
import Status from "./Status/status.jsx";
import History from "./History/History.jsx";
import { useSelector } from "react-redux";
import Logs from "./Logs/Logs.jsx";
import Metrics from "./Metrics/Metrics.jsx";
import { useGetPages } from "../../hooks/hooks.jsx";
import Workstations from "./Workstations/Workstations.jsx";
import Chutes from "./Chutes/Chutes.jsx";
import Operational from "./Operational/Operational.jsx";
import PlcMessages from "./Plc-Messages/Plc.jsx";
import PlcLogs from "./Plc-Logs/Plc.jsx";
import Model from "./Layout/Model.jsx";


const ProjectApp = ({username}) => {
  const project = useSelector((state) => state.project);



   useGetPages({
    endpoint: `${configData.SERVER_URL}pages/${project.id}`,
    requestId: `${project.id}-pages`,
    
  });

  


  return (
    <>
      <Routes>
        <Route path={`/`} exact={true} element={<Home />} />
        <Route path={`/Home`} exact={true} element={<Home />} />
        <Route  path={`/Dashboard`} exact={true} element={<Dashboard username={username}/>} />
        <Route path={`/Scanners`} exact={true} element={<Scanners />} />
        <Route path={`/History`} exact={true} element={<History />} />
        <Route path={`/Logs`} exact={true} element={<Logs />} />
        <Route path={`/Metrics`} exact={true} element={<Metrics />} />
        <Route path={`/Status`} exact={true} element={<Status />} />
        <Route path={`/Workstations`} exact={true} element={<Workstations />} />
        <Route path={`/Chutes`} exact={true} element={<Chutes />} />
        <Route path={`/Operational`} exact={true} element={<Operational />} />
        <Route path={`/PLC-messages`} exact={true} element={<PlcMessages />} />
        <Route path={`/PLC-logs`} exact={true} element={<PlcLogs />} />
        <Route path={`/3D-Layout`} exact={true} element={<Model />} />
      </Routes>
    </>
  );
};

export default ProjectApp;
