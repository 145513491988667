import React, { useState } from "react";
import { Link, useNavigate, useParams, useSearchParams } from "react-router-dom";
import styled from "@emotion/styled";
import { Breadcrumbs, Button } from "@mui/material";
import MachineScreen from "./MachineContent.jsx";
import { Close } from "@mui/icons-material";

export default function Machine() {
  const params = useParams();
  let [searchParams] = useSearchParams();
  const token = searchParams.get("token");
  const name = searchParams.get("name");
  const navigate = useNavigate();

  return (
    <>
    <div style={{display:"flex", justifyContent:"space-between"}}>
      <StyledBreadcrumbs maxItems={3} aria-label="breadcrumb">
      <StyledLink to="/">Centralized service portal</StyledLink>
        <StyledLink underline="hover" color="ButtonText" to={`/Dialin`}>
          Dial-in
        </StyledLink>
        <StyledLink
          last="true"
          underline="hover"
          color="ButtonText"
          to={`/Dialin/${params.machine}`}
        >
          {name}
        </StyledLink>
      </StyledBreadcrumbs>
      <Button sx={{marginX:"10px"}} variant="contained" onClick={()=>navigate(-1)} color="error"><Close/> Close connection</Button>
    </div>
      <StyledContentContainer>
        <MachineScreen token={token}/>
      </StyledContentContainer>
    </>
  );
}
const StyledContentContainer = styled.div`
  height: 100%;
  display: flex;

  justify-content: center;
`;

const StyledBreadcrumbs = styled(Breadcrumbs)`
  margin: 10px;
`;

const StyledLink = styled(Link)`
  text-decoration: none;
  color: rgba(0, 0, 0, 0.6);
  color: ${(props) => (props.last === "true" ? "black" : "rgba(0,0,0,0.6)")};
  &:hover {
    text-decoration: underline;
  }
`;
