import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import configData from "../../../config.js";
import { Button } from "@mui/material";

import { CalendarToday, History } from "@mui/icons-material";
import ScannerHistory from "./ScannerHistory.jsx";
import TableWithControls from "../../Shared/TableWithControls.jsx";
import { tableDataActionsCreators } from "../../../state/index.js";
import { bindActionCreators } from "redux";
import ScannerDaily from "./ScannerDaily.jsx";
import styled from "@emotion/styled";

const ScannersContent = () => {
  const gridRef = useRef();
  const project = useSelector((state) => state.project);
  const dispatch = useDispatch();

  const { setTableData } = bindActionCreators(
    tableDataActionsCreators,
    dispatch
  );

  useEffect(() => {
    return () => {
      setTableData([]);
    };
  }, [setTableData]);

  const [clickedData, setClickedData] = useState(null);
  const [scannerId, setScannerId] = useState(null);
  const [isHistoryOpen, setHistoryOpen] = React.useState(false);
  const [isDailyOpen, setDailyOpen] = React.useState(false);

  const [columns, setColumns] = useState({
    scannerId: true,
    success: true,
    noRead: true,
    history: true,
    daily: true,
  });

  const toggleColumn = useCallback((column) => {
    setColumns((prevColumns) => ({
      ...prevColumns,
      [column]: !prevColumns[column],
    }));
  }, []);

  const handleHistoryClose = () => {
    setHistoryOpen(false);
    setTableData(gridRef.current.props.rowData);
  };
  const handleDailyClose = () => {
    setDailyOpen(false);
    setTableData(gridRef.current.props.rowData);
  };

  const renderColor = (value) => {
    const numericValue = parseFloat(value);
    if (numericValue > 99) {
      return "#206A03";
    } else if (numericValue <= 99 && numericValue > 98) {
      return "#A06102";
    } else {
      return "#720102";
    }
  };

  const columnDefs = useMemo(
    () => [
      {
        field: "scannerId",
        suppressSizeToFit: true,
        hide: !columns.scannerId,
      },
      {
        field: "success",
        suppressSizeToFit: true,
        hide: !columns.success,
        valueGetter: (params) => {
          const percentageNumber =
            (params.data.success / params.data.total) * 100;
          return !params.data.total ? "-%" : `${percentageNumber.toFixed(2)}%`;
        },
        cellRenderer: ({ value }) => {
          return <span style={{ color: renderColor(value) }}>{value}</span>;
        },
      },
      {
        field: "noRead",
        hide: !columns.noRead,
        suppressSizeToFit: true,
        valueGetter: (params) => {
          const percentageNumber =
            (params.data.noRead / params.data.total) * 100;
          return !params.data.total ? "-%" : `${percentageNumber.toFixed(2)}%`;
        },
        cellRenderer: ({ value, data }) => {
          return (
            <span
              style={{ color: renderColor((data.success / data.total) * 100) }}
            >
              {value}
            </span>
          );
        },
      },
      {
        hide: !columns.history,
        field: "history",
        cellRenderer: (params) => (
          <Button
            variant="contained"
            sx={{ background: "#508C9E", height: "25px", fontSize: "15px" }}
            onClick={() => {
              handleHistoryOpen(params);
            }}
          >
            <History sx={{ fontSize: "15px", marginRight: "5px" }} />
            <StyledButtonText>History</StyledButtonText>
          </Button>
        ),
      },
      {
        hide: !columns.daily,
        field: "daily",
        cellRenderer: (params) => (
          <Button
            sx={{ background: "#508C9E", height: "25px", fontSize: "15px" }}
            variant="contained"
            onClick={() => {
              handleDailyOpen(params);
            }}
          >
            <CalendarToday sx={{ fontSize: "15px", marginRight: "5px" }} />{" "}
            <StyledButtonText>Daily</StyledButtonText>
          </Button>
        ),
      },
    ],
    [columns]
  );

  const handleHistoryOpen = (params) => {
    setClickedData(params.data);
    setHistoryOpen(true);
    setScannerId(params.data.scannerId);
  };
  const handleDailyOpen = (params) => {
    setClickedData(params.data);
    setDailyOpen(true);
    setScannerId(params.data.scannerId);
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        height: "100%",
        position: "relative",
        overflow: "hidden",
      }}
    >
      <TableWithControls
        toggleColumn={toggleColumn}
        gridRef={gridRef}
        columns={columns}
        columnDefs={columnDefs}
        hasDateFilter={!isDailyOpen && !isHistoryOpen}
        endpoint={`${configData.SERVER_URL}Scans/Summary/${project.id}`}
        parameters={{ daysago: 1 }}
        requestId={`${project.id}-scanners-summary`}
        hasFirstDateColumn={false}
      />
      {isHistoryOpen && clickedData ? (
        <ScannerHistory
          scannerId={scannerId}
          data={clickedData}
          open={isHistoryOpen}
          handleClose={handleHistoryClose}
        />
      ) : null}
      {isDailyOpen && clickedData ? (
        <ScannerDaily
        renderColor={renderColor}
          scannerId={scannerId}
          data={clickedData}
          open={isDailyOpen}
          handleClose={handleDailyClose}
        />
      ) : null}
    </div>
  );
};

const StyledButtonText = styled.span`
  @media screen and (max-width: 750px) {
    display: none;
  }
`;
export default ScannersContent;
