import { Routes, Route, useNavigate } from "react-router-dom";
import axios from "axios";
import configData from "./config.js";
import jwt_decode from "jwt-decode";
import { useEffect, useState } from "react";
import React from "react";
import { toast, Toaster } from "react-hot-toast";
import Header from "./Components/Header/Header";
import Projects from "./Components/Projects/Projects";
import ProjectApp from "./Components/InProject/ProjectApp";
import { bindActionCreators } from "redux";
import { projectActionsCreators } from "./state/index";
import { useDispatch, useSelector } from "react-redux";
import ActionsButton from "./Components/ActionsButton/ActionsButton";
import styled from "@emotion/styled";
import NewLogin from "./Components/Login/NewLogin.jsx";
import TermsAndConditionsDialog from "./Components/TermsAndConditions/TermsAndConditionsDialog.jsx";
import TermsAndConditions from "./Components/TermsAndConditions/TermsAndConditions.jsx";import Machine from "./Components/Dialin/Machine/Machine.jsx";
import Dialin from "./Components/Dialin/Dialin.jsx";
;


function App() {
  const [token, setToken] = useState(
    sessionStorage.getItem("accessToken")
      ? sessionStorage.getItem("accessToken")
      : localStorage.getItem("accessToken")
  );
  const project = useSelector((state) => state.project);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { setProject } = bindActionCreators(projectActionsCreators, dispatch);

  useEffect(() => {
    const storedProject = window.sessionStorage.getItem("project");
    if (storedProject) {
      setProject(JSON.parse(storedProject));
    } else {
      navigate("/");
    }
  }, [window.sessionStorage.getItem("project")]);

  const username =
    token && sessionStorage.getItem("accessToken")
      ? jwt_decode(sessionStorage.getItem("accessToken")).name
      : token && localStorage.getItem("accessToken")
      ? jwt_decode(localStorage.getItem("accessToken")).name
      : null;

  const hasAcceptedTerms = token
    ? jwt_decode(sessionStorage.getItem("accessToken"))?.terms
    : false;

  const handleHasAcceptedTerms = () => {
    axios
      .post(`${configData.SERVER_URL}terms`)
      .then((res) => {
        sessionStorage.clear();
        sessionStorage.setItem("accessToken", res.data.access_token);
        sessionStorage.setItem("refreshToken", res.data.refresh_token);
      })
      .then(() => setToken(sessionStorage.getItem("accessToken")));
  };

  const handleLogout = () => {
    sessionStorage.clear();
    localStorage.clear();
    window.location.replace("/");
  };

  axios.interceptors.request.use(
    (config) => {
      if (sessionStorage.getItem("accessToken")) {
        config.headers["Authorization"] =
          "Bearer " + sessionStorage.getItem("accessToken");
      }

      config.headers["Content-Type"] = "application/json";
      return config;
    },

    (error) => {
      Promise.reject(error);
    }
  );

  axios.interceptors.response.use(
    (response) => {
      return response;
    },
    function (error) {
      const originalRequest = error.config;
      if (
        error.response.status !== 401 &&
        originalRequest.url === `${configData.SERVER_URL}Auth/refresh`
      ) {
        setToken(sessionStorage.getItem("accessToken"));
        return Promise.reject(error);
      } else if (
        error.response.status === 401 &&
        originalRequest.url === `${configData.SERVER_URL}Auth/refresh`
      ) {
        sessionStorage.clear();
        setToken(sessionStorage.getItem("accessToken"));
        window.location.replace("/");
        toast.error("token expired");
      } else if (
        error.response.status === 401 &&
        originalRequest.url === `${configData.SERVER_URL}Auth/Login`
      ) {
        toast.error(error.response.data);
        return Promise.reject(error);
      } else if (
        error.response.status === 401 &&
        originalRequest.url !== `${configData.SERVER_URL}Auth/refresh` &&
        sessionStorage.getItem("accessToken")
      ) {
        originalRequest._retry = true;
        sessionStorage.removeItem("accessToken");

        const refreshToken = sessionStorage.getItem("refreshToken");
        let config = {
          headers: {
            Authorization: "Bearer " + refreshToken,
          },
        };

        return axios
          .post(`${configData.SERVER_URL}Auth/refresh`, {}, config)
          .then((res) => {
            sessionStorage.clear();
            sessionStorage.setItem("accessToken", res.data.access_token);
            sessionStorage.setItem("refreshToken", res.data.refresh_token);
            return res;
          })
          .then((res) => {
            axios.defaults.headers.common["Authorization"] =
              "Bearer " +
              sessionStorage.getItem("accessToken", res.data.access_token);
            return axios(originalRequest);
          });
      } else if (
        !error.response.status &&
        originalRequest.url !== `${configData.SERVER_URL}Auth/Login`
      ) {
        toast.error("couldn't connect to the server, please try again");

        sessionStorage.clear();
        setToken(sessionStorage.getItem("accessToken"));
        window.location.replace("/");
      }
    }
  );

  return (
    <>
      <AppContainer className="app">
        {token ? (
          <Header
            username={username}
            handleLogout={handleLogout}
            project={project}
          />
        ) : null}
        {token ? <ActionsButton /> : null}

        <Routes>
          <Route
            index
            path="/"
            exact={true}
            element={token ? <Projects /> : <NewLogin />}
          />
          <Route
            index
            path="/TermsAndConditions"
            exact={true}
            element={token ? <TermsAndConditions /> : <NewLogin />}
          />
          <Route
            index
            path="/Dialin"
            exact={true}
            element={token ? <Dialin /> : <NewLogin />}
          />
          <Route
            index
            path="/Dialin/machine/connection"
            exact={true}
            element={token ? <Machine /> : <NewLogin />}
          />
          {project ? (
            <Route
              index
              path={project.name.replace(/ /g, "-") + "/*"}
              element={
                token ? (
                  <ProjectApp username={username} project={project} />
                ) : (
                  <NewLogin />
                )
              }
            />
          ) : null}
        </Routes>
      </AppContainer>
      {!hasAcceptedTerms && token && (
        <TermsAndConditionsDialog
          handleLogout={handleLogout}
          open={!hasAcceptedTerms}
          handleClose={handleHasAcceptedTerms}
        />
      )}
      <Toaster position="bottom-left" />
    </>
  );
}
const AppContainer = styled.div`
  height: 100vh;
  position: relative;
  display: flex;
  flex-direction: column;

  background-color: ${(props) => {
    return props.theme.palette.background.default;
  }};
`;
export default App;
