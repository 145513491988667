import { useSelector } from "react-redux";
const ModelContent = () => {
  const project = useSelector((state) => state.project);



  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        height: "100%",
        position: "relative",
        overflow: "hidden",
      }}
    >
      { project.layoutLink?
             <iframe
             src={project.layoutLink}
             title="3D Model Viewer"
             width="100%"
             height="100%"
             allowFullScreen
             />
            :<h1>No layout</h1>}
    </div>
  );
};

export default ModelContent;
